
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleUsers from './modules/users.js'
import moduleNotifications from "./modules/notifications.js"
import moduleCaseCategory from './modules/case-categories.js'
import moduleCaseStatus from './modules/case-statuses.js'
import moduleDepartment from './modules/departments.js'
import moduleCase from './modules/cases.js'
import moduleLawyers from './modules/lawyers.js'
import moduleDuty from './modules/duties.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        userList: moduleUsers,
        notification: moduleNotifications,
        caseCategory: moduleCaseCategory,
        department: moduleDepartment,
        case: moduleCase,
        caseStatus: moduleCaseStatus,
        lawyer: moduleLawyers,
        duty: moduleDuty 
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
